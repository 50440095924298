import React from "react";
import "./Message.css";
import {
  G4Cliente,
  G4Wait,
  G4Atendente,
} from "../../pages/conversation/components/styled";
import { useSelector } from "react-redux";

import { Hours } from "../styled";
import { useContext } from "react";
import { WebchatContext } from "../../context/WebchatContext";

const Message = ({ time, user, text, size }) => {
  const config = useSelector((state) => state.config);
  const { modeWebchatFull } = useContext(WebchatContext);

  const modifyTextWithLinks = (text) => {
    if (!text) return "";
    const linkRegex = /<a\s+link="([^"]+)">\s*([^<]+)\s*<\/a>/g;

    let modifiedText = text.replace(
      linkRegex,
      `<a style="color: ${
        config.colorHyperlink || "blue"
      }; font-weight:800; text-decoration: none; cursor: pointer;" href="$1" target="_blank">$2</a>`
    );

    const urlRegex = /(?<!href=")(https?:\/\/[^\s]+)/g;
    modifiedText = modifiedText.replace(
      urlRegex,
      `<a  style="color: ${
        config.colorHyperlink || "blue"
      }; font-weight:800; text-decoration: none; cursor: pointer;" href="$1" target="_blank">$1</a>`
    );
    const boldRegex = /\*([^*]+)\*/g;

    return modifiedText.replace(boldRegex, "<b>$1</b>");
  };

  const content = (
    <>
      <div
        id="text_G4_flex"
        dangerouslySetInnerHTML={{ __html: modifyTextWithLinks(text) }}
      ></div>
      <Hours>
        {new Date(time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })}
      </Hours>
    </>
  );

  return (
    <>
      {user === "cliente" && (
        <G4Cliente
          modeWebchatFull={modeWebchatFull}
          backgroundColorConfig={config?.backgroundBallonClient}
          colorText={config?.textColorBallonClient}
        >
          {content}
        </G4Cliente>
      )}
      {user === "atendente" && (
        <G4Atendente
          backgroundColorConfig={config?.backgroundBallonAttendant}
          colorText={config?.textColorBallonAttendant}
          modeWebchatFull={modeWebchatFull}
        >
          {content}
        </G4Atendente>
      )}
      {user === "wait" && <G4Wait color={config.color}>{content}</G4Wait>}
    </>
  );
};

export default Message;
