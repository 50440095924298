import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "./style";
import { WebchatContext } from "../../context/WebchatContext";

const Loader = () => {
  const { isMobile } = useContext(WebchatContext);

  const config = useSelector((state) => state.config);
  return <Spinner isMobile={isMobile} picker={config.color} />;
};

export default Loader;
