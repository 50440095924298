export function addConfig(
  color,
  useBot,
  botName,
  colorHyperlink,
  typeOfOpeningChat,
  nameOfOpeningChat,
  imageOfOpeningChat,
  iconOfOpeningChat,
  backgroundBallonAttendant,
  backgroundBallonClient,
  textColorBallonAttendant,
  textColorBallonClient,
  textContrastColor,
  colorButtonLogin
) {
  return {
    type: "@config/ADD_CONFIG",
    payload: {
      color,
      useBot,
      botName,
      colorHyperlink,
      typeOfOpeningChat,
      nameOfOpeningChat,
      imageOfOpeningChat,
      iconOfOpeningChat,
      backgroundBallonAttendant,
      backgroundBallonClient,
      textColorBallonAttendant,
      textColorBallonClient,
      textContrastColor,
      colorButtonLogin,
    },
  };
}
