import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./components/Header.css";
import { G4Header, G4Avatar, G4HeaderItens, SpanG4 } from "./components/styled";
import { getLogoFlexiaURL } from "../logos/index";

import { FaMinus } from "react-icons/fa";
import { useContext } from "react";
import { WebchatContext } from "../../context/WebchatContext";
//import { clearConversation } from '../../store/conversation/actions';

const Header = ({ minimize, size }) => {
  const [logoFlexiaURL, setLogoFlexiaURL] = useState(null);

  const { modeWebchatFull, isMobile } = useContext(WebchatContext);

  useEffect(() => {
    getLogoFlexiaURL()
      .then((source) => {
        setLogoFlexiaURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading flexia logo"));
  }, []);

  const config = useSelector((state) => state.config);
  const hours = useSelector((state) => state.hoursOfOut);
  //const dispatch = useDispatch();
  function minus(event) {
    event.preventDefault();
    minimize(false);
  }

  return (
    <>
      <G4Header
        modeWebchatFull={modeWebchatFull}
        color={config.color}
        chatOpen={size}
      >
        <div>
          <div>
            <G4Avatar
              modeWebchatFull={modeWebchatFull}
              alt="avatar"
              chatOpen={size}
              src={logoFlexiaURL}
            />
          </div>
        </div>
        <G4HeaderItens
          textContrastColor={config.textContrastColor}
          modeWebchatFull={modeWebchatFull}
          onlineAtendent={hours}
        >
          <div modeWebchatFull={modeWebchatFull} className="circleOnline" />
          <div className="status">
            <SpanG4>{config.botName}</SpanG4>
          </div>
        </G4HeaderItens>
        {!isMobile && (
          <div className="minimize">
            <FaMinus
              onClick={(event) => minus(event)}
              size="23"
              color="#fff"
              type="button"
            />
          </div>
        )}
      </G4Header>
    </>
  );
};

export default Header;
