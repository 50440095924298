import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import {
  G4Container,
  G4FullWindow,
  G4LogoOpen,
  ImageOpen,
  Switch,
} from "./components/styled";
import "./config/ReactotronConfig";
import Header from "./components/header/Header";
import Login from "./pages/login/Login";
import Queue from "./pages/queue/Queue";
import Wait from "./pages/wait/Wait";
import Conversation from "./pages/conversation/Conversation";
import CustumerExperienceSurvey from "./pages/customer-experience-survey/CustumerExperienceSurvey";
import End from "./pages/end/End";
import { FaComments } from "react-icons/fa";

import { WebchatContext } from "./context/WebchatContext";

import "./services/get-config-chat";
import { subscribe } from "./util/events";
import { G4LoginImg, G4LoginImgDiv } from "./pages/login/components/styled";
import { getLogoChatURL } from "./components/logos";

const LOGIN = "Login";
const QUEUE = "Queue";
const WAIT = "Wait";
const CONVERSATION = "Conversation";
const CUSTOMER_EXPERIENCE = "CustomerExperience";
const END = "End";

const App = () => {
  const page = useSelector((state) => state.page);
  const config = useSelector((state) => state.config);
  const { modeWebchatFull, isMobile } = useContext(WebchatContext);
  const [chatOpen, setChatOpen] = useState(isMobile ? true : false);
  const [logoURL, setLogoURL] = useState(null);

  const style = isMobile
    ? {
        zIndex: 20000,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
    : { zIndex: 20000 };

  subscribe("openWebChat", () => openChat());

  function openChat() {
    setChatOpen(true);
  }

  function minimize(value) {
    setChatOpen(value);
  }

  console.log("config", config);

  useEffect(() => {
    getLogoChatURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error("[LOGIN FORM] Error loading chat logo"));
  }, []);

  const getTypeOfOpeningChat = () => {
    if (config?.typeOfOpeningChat === "ICON" && config.iconOfOpeningChat) {
      return <ImageOpen src={config.iconOfOpeningChat} alt="logo" />;
    } else if (
      config?.typeOfOpeningChat === "ICON" &&
      !config.iconOfOpeningChat
    ) {
      return (
        <FaComments
          onClick={() => {
            openChat();
          }}
          size="40"
          color="#fff"
        />
      );
    } else if (config?.typeOfOpeningChat === "TEXT") {
      return config?.nameOfOpeningChat;
    }
  };
  return (
    <>
      {modeWebchatFull ? (
        <G4FullWindow>
          <G4LoginImgDiv isMobile={isMobile}>
            <G4LoginImg src={logoURL} alt="logo" />
          </G4LoginImgDiv>

          <G4Container
            isMobile={isMobile}
            page={page}
            modeWebchatFull={modeWebchatFull}
            style={{ zIndex: 20000 }}
            openDisplay={true}
          >
            <Header />
            {page === LOGIN ? <Login /> : ""}
            {page === QUEUE ? <Queue /> : ""}
            {page === WAIT ? <Wait /> : ""}
            {page === CONVERSATION ? <Conversation /> : ""}
            {page === CUSTOMER_EXPERIENCE ? <CustumerExperienceSurvey /> : ""}
            {page === END ? <End /> : ""}
          </G4Container>
        </G4FullWindow>
      ) : (
        <div style={style}>
          {!chatOpen && config.color !== "" && (
            <G4LogoOpen
              color={config.color}
              textContrastColor={config.textContrastColor}
              style={{ zIndex: 20000 }}
              onClick={() => {
                openChat();
              }}
              isIcon={config?.typeOfOpeningChat === "ICON"}
              isImage={config?.typeOfOpeningChat === "IMAGE"}
              isText={config?.typeOfOpeningChat === "TEXT"}
              logoURL={config?.imageOfOpeningChat}
            >
              {getTypeOfOpeningChat()}
            </G4LogoOpen>
          )}

          <G4Container
            isMobile={isMobile}
            style={{ zIndex: 20000 }}
            openDisplay={chatOpen}
          >
            <Header minimize={minimize} />
            {page === LOGIN ? <Login /> : ""}
            {page === QUEUE ? <Queue /> : ""}
            {page === WAIT ? <Wait /> : ""}
            {page === CONVERSATION ? <Conversation /> : ""}
            {page === CUSTOMER_EXPERIENCE ? <CustumerExperienceSurvey /> : ""}
            {page === END ? <End /> : ""}
          </G4Container>
        </div>
      )}
    </>
  );
};

export default App;
