import { apiHttpNotOpen } from "./api-http";

import store from "../store";
import { addConfig } from "../store/config/actions";

apiHttpNotOpen
  .get("api/open/configurations/webchat")
  .then((response) => {
    // const color = 'true';
    const useBot = response.data.data.ativado;
    const botName = response.data.data.nome_atendente;
    const color = response.data.data.cor || "#2C984A";

    // const useBot = false;

    store.dispatch(
      addConfig(
        color,
        useBot,
        botName,
        response.data.data.hyperLinkColor || "#2C984A",
        response.data.data.typeOfOpeningChat || "ICON",
        response.data.data.nameOfOpeningChat,
        response.data.data.imageOfOpeningChat,
        response.data.data.iconOfOpeningChat,
        response.data.data.backgroundBallonAttendant,
        response.data.data.backgroundBallonClient,
        response.data.data.textColorBallonAttendant,
        response.data.data.textColorBallonClient,
        response.data.data.textContrastColor || "#fff",
        response.data.data.backgroundButtonLogin || color
      )
    );
  })
  .catch((err) => {
    console.log(err?.message || "Error!");
  });
